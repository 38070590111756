<div class="a-box -floating-shadow-s chat-container">
  <div class="chat-close-button-container">
    <button
      (click)="clearChat()"
      type="button"
      class="a-button a-button--tertiary chat-clear"
    >
      <i
        class="a-icon a-button__icon boschicon-bosch-ic-broom-clean"
        title="clear chat"
      ></i>
      <span class="a-button__label">{{
        'copilot.buttons.clearConversation' | translate
      }}</span>
    </button>
    <button
      (click)="closed.emit(true)"
      type="button"
      class="a-button a-button--tertiary -fixed chat-close"
    >
      <i
        class="a-icon a-button__icon ui-ic-close"
        title="close"
      ></i>
      <span class="a-button__label">{{ 'common.close' | translate }}</span>
    </button>
  </div>

  <div
    class="messages"
    [class.messages--none]="(messages$ | async)?.length === 0"
    #messagesContainer
  >
    <div
      class="messages-hint"
      *ngIf="(messages$ | async)?.length === 0"
    >
      <div class="messages-hint-role">
        <ng-container *ngTemplateOutlet="userProfile"></ng-container>
        <span>ASSISTANT</span>
      </div>
      <div [innerHTML]="copilotService.chatIntroText | async"></div>
    </div>

    <ng-container *ngFor="let message of messages$ | async">
      <div
        [ngClass]="{
          'message--user': message.role === 'user',
          'message--assistant': message.role === 'assistant'
        }"
      >
        <div class="message--role">
          <ng-container *ngIf="message.role === 'user'; else assistantProfile">
            <ng-container *ngTemplateOutlet="userProfile"></ng-container>
          </ng-container>
        </div>
        <div
          class="message--content"
          [innerHTML]="message.content"
        ></div>
      </div>
    </ng-container>
    <div
      *ngIf="copilotService.loading | async"
      class="message--assistant"
    >
      <div class="message--role">
        <ng-container *ngTemplateOutlet="assistantProfile"></ng-container>
      </div>
      <div class="message--content">
        <copilot-typing-effect></copilot-typing-effect>
      </div>
    </div>
  </div>
  <div class="user-input-container">
    <app-user-query-input
      [disabled]="(copilotService.loading | async)!"
      [maxLength]="maxUserInput"
      (userInput)="onUserInput($event)"
    ></app-user-query-input>
  </div>
</div>
<ng-template #userProfile>
  <i
    class="a-icon boschicon-bosch-ic-my-brand-frame chat-icon"
    title="user-profile"
  ></i>
</ng-template>
<ng-template #assistantProfile>
  <i
    class="a-icon boschicon-bosch-ic-user-robot-head chat-icon"
    title="user-robot-head"
  ></i>
</ng-template>
