<div class="a-text-field user-input-container">
  <div class="user-input-wrapper">
    <div class="a-text-area textarea-container">
      <textarea
        [formControl]="inputControl"
        (keydown.enter)="onEnterPressed()"
        [placeholder]="'copilot.userInput.placeholder' | translate"
      ></textarea>
    </div>

    <div
      class="a-sticker -secondary limit-indicator"
      aria-labelledby="sticker-label-id-secondary"
    >
      <span
        id="sticker-label-id-secondary"
        class="a-sticker__label -size-s"
      >
        {{ inputControl.value?.length || 0 }}/{{ maxLength }}
      </span>
    </div>
  </div>
  <button
    fxLayout="row"
    fxLayoutAlign="center center"
    (click)="onEnterPressed()"
    type="button"
    class="a-button a-button--secondary -without-label send-icon"
    aria-label="accessibility label"
    [disabled]="disabled"
  >
    <i
      class="a-icon boschicon-bosch-ic-paperplane"
      title="paperplane"
    ></i>
  </button>
</div>
