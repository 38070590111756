import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { CopilotService } from '../../shared/services/copilot.service';
import { ChatWindowComponent } from './chat-window/chat-window.component';

@Component({
  selector: 'app-co-pilot',
  standalone: true,
  imports: [CommonModule, ChatWindowComponent],
  templateUrl: './co-pilot.component.html',
  styleUrls: ['./co-pilot.component.scss'],
})
export class CoPilotComponent {
  chatWindowIsActive = false;

  constructor(private copilotService: CopilotService) {
    this.copilotService.chatWindowCurrentVisibility.subscribe(
      (visible) => (this.chatWindowIsActive = !!visible)
    );
  }

  toggleChat() {
    this.copilotService.setChatWindowVisibility(!this.chatWindowIsActive);
  }
}
