import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { tap } from 'rxjs';
import { CopilotService } from '../../../shared/services/copilot.service';
import { TypingEffectComponent } from '../typing-effect/typing-effect.component';
import { UserQueryInputComponent } from '../user-query-input/user-query-input.component';

@Component({
  selector: 'app-chat-window',
  standalone: true,
  imports: [
    CommonModule,
    UserQueryInputComponent,
    TranslateModule,
    TypingEffectComponent,
  ],
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.scss'],
})
export class ChatWindowComponent {
  @ViewChild('messagesContainer') messagesContainer!: ElementRef;
  @Output() closed = new EventEmitter<boolean>();

  readonly maxUserInput = 250;
  messages$ = this.copilotService.messages.pipe(
    tap((messages) => {
      if (messages.length > 0) this.scrollToBottom();
    })
  );
  constructor(public copilotService: CopilotService) {}

  async onUserInput(userMessage: string) {
    if (!userMessage) {
      return;
    }

    this.copilotService.sendUserMessage(userMessage);
  }

  scrollToBottom() {
    setTimeout(() => {
      this.messagesContainer.nativeElement.scroll({
        top: this.messagesContainer.nativeElement.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    }, 200);
  }

  clearChat() {
    this.copilotService.clearChat();
  }
}
