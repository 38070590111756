<div class="main-container">
  <div
    class="chat-window"
    [ngClass]="{ open: chatWindowIsActive, close: !chatWindowIsActive }"
  >
    <app-chat-window (closed)="toggleChat()"></app-chat-window>
  </div>
  <button
    *ngIf="!chatWindowIsActive"
    (click)="toggleChat()"
    type="button"
    class="a-button a-button--primary -without-label chat-floating-widget"
    aria-label="accessibility label"
  >
    <i
      class="a-icon boschicon-bosch-ic-user-robot-head chat-icon"
      title="user-robot-head"
    ></i>
  </button>
</div>
